import React, { MouseEventHandler } from 'react';
import { getPlanName } from 'lib/i18n';
import styled, { useTheme } from 'styled-components';
import * as tokens from 'styles';
import { PLAN_FAMILY } from 'lib/plans';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import Type from 'components/Type';
import WidthWrapper from 'components/SubLandingPage/Family/WidthWrapper';
import LeadParagraph from 'components/SubLandingPage/LeadParagraph';
import UnderlinedLink from 'components/Link/UnderlinedLink';
import { useTranslation } from 'data/translation/useTranslation';

const SecondaryHeader = styled(LeadParagraph)`
  padding-bottom: ${tokens.spacer32};
`;

const Wrapper = styled(WidthWrapper)`
  padding-top: ${tokens.spacer32};
  padding-bottom: ${tokens.spacer80};
`;

export const Main = styled.header`
  margin: 0 auto;
  margin-bottom: ${tokens.spacer32};
  max-width: 630px;
`;

type InvitationDisplayProps = {
  joinUrl: string;
  termsUrl: string;
  onJoinClick: MouseEventHandler;
  onTermsClick: MouseEventHandler;
};

export const InvitationDisplay = ({
  joinUrl,
  termsUrl,
  onJoinClick,
  onTermsClick,
}: InvitationDisplayProps) => {
  const { t, interpolate } = useTranslation();
  const theme = useTheme();
  return (
    <section role="presentation">
      <Wrapper>
        <Main>
          <Type.H1 paddingBottom={tokens.spacer32} variant="forte">
            {t('sub_landing_page.invited_to_join_a_plan', {
              plan_name: t('family'),
            })}
          </Type.H1>
          <SecondaryHeader>
            {t('sub_landing_page.family.log_in_existing_account')}
          </SecondaryHeader>

          <PrimaryButton
            color={tokens.white}
            href={joinUrl}
            onClick={onJoinClick}
            $alternateFamily
          >
            {t('sub_landing_page.accept_invite')}
          </PrimaryButton>
        </Main>
        <footer>
          <Type.P variant="finale">
            {t('sub_landing_page.terms.no_refunds_v2')}
          </Type.P>
          <Type.P variant="finale">
            {interpolate('sub_landing_page.terms.by_accepting_agree', {
              plan_name_terms: (
                <UnderlinedLink
                  href={termsUrl}
                  onClick={onTermsClick}
                  style={{ color: theme.textColor }}
                >
                  {t('sub_landing_page.plan_name_terms', {
                    plan_name: getPlanName(PLAN_FAMILY, t),
                  })}
                </UnderlinedLink>
              ),
            })}
          </Type.P>
        </footer>
      </Wrapper>
    </section>
  );
};

export default InvitationDisplay;
