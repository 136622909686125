import React from 'react';
import Head from 'next/head';
import SubLandingPage from 'containers/Join/Family';
import config from 'config.json';
import { useTranslation } from 'data/translation/useTranslation';
import { useRouter } from 'next/router';
import { generateFullPath } from 'lib/url';

const Index = () => {
  const router = useRouter();
  const { t, locale } = useTranslation();
  const openGraphImage = generateFullPath(
    '/img/og/family/open-graph-family-2.png',
  );

  return (
    <>
      <Head>
        <meta
          name="description"
          content={t('sub_landing_page.six_accounts_under_one_roof')}
        />
        <meta property="fb:app_id" content={config.facebookAppId} />
        <meta
          property="og:title"
          content={t('sub_landing_page.invited_to_join_a_plan', {
            plan_name: t('family'),
          })}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.spotify.com/${locale}${router.asPath}`}
        />
        <meta property="og:image" content={openGraphImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:secure_url" content={openGraphImage} />
        <meta
          property="og:description"
          content={t('sub_landing_page.six_accounts_under_one_roof')}
        />
      </Head>
      <SubLandingPage />
    </>
  );
};

export default Index;
