import React from 'react';
import { getPlanName } from 'lib/i18n';
import { PLAN_FAMILY } from 'lib/plans';
import LeadParagraph from 'components/SubLandingPage/LeadParagraph';
import SectionHeadline from 'components/SubLandingPage/SectionHeadline';
import WidthWrapper from 'components/SubLandingPage/Family/WidthWrapper';
import { useTranslation } from 'data/translation/useTranslation';
import * as tokens from 'styles';

const PremiumDisplay = () => {
  const { t } = useTranslation();
  return (
    <WidthWrapper>
      {/* eslint-disable @spotify-internal/wtb-rules/no-danger-styled */}
      <SectionHeadline
        paddingBottom={tokens.spacer32}
        dangerouslySetInnerHTML={{
          __html: t('sub_landing_page.pay_nothing_stream_everything'),
        }}
      />
      {/* eslint-enable @spotify-internal/wtb-rules/no-danger-styled */}
      <LeadParagraph>
        {t('sub_landing_page.account_already_paid_for_plan', {
          plan_name: getPlanName(PLAN_FAMILY, t),
        })}
      </LeadParagraph>
    </WidthWrapper>
  );
};

export default PremiumDisplay;
