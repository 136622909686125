import React from 'react';
import * as tokens from 'styles';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import { getFamilySubLandingPageCoversUrl } from 'lib/localized-assets';
import WidthWrapper from 'components/SubLandingPage/Family/WidthWrapper';
import LeadParagraph from 'components/SubLandingPage/LeadParagraph';
import SectionHeadline from 'components/SubLandingPage/SectionHeadline';
import { useTranslation } from 'data/translation/useTranslation';

const Wrapper = styled.section`
  background: ${tokens.white};
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const ListenTogetherContainer = styled(WidthWrapper)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${media.mobileMdUp} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${tokens.spacer64} 0 ${tokens.spacer24} 0;
  word-break: break-word;

  ${media.mobileMdUp} {
    text-align: left;
    padding: ${tokens.spacer64} ${tokens.spacer24} ${tokens.spacer64} 0;
    width: 45%;
  }
`;

type ImageContainerProps = {
  $mobileImg: string;
  $tabletImg: string;
  $desktopImg: string;
};

export const ImageContainer = styled.div<ImageContainerProps>`
  width: 120%;
  max-width: 500px;
  height: 230px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;
  background-image: url(${({ $mobileImg }) => $mobileImg});
  ${media.mobileXsUp} {
    background-image: url(${({ $tabletImg }) => $tabletImg});
    width: 100%;
    height: 250px;
  }
  ${media.mobileMdUp} {
    background-image: url(${({ $desktopImg }) => $desktopImg});
    height: 300px;
  }
`;

const PlaylistsDisplay = () => {
  const { locale, t } = useTranslation();
  const cover = getFamilySubLandingPageCoversUrl(locale);
  return (
    <Wrapper>
      <ListenTogetherContainer>
        <Content>
          {/* eslint-disable @spotify-internal/wtb-rules/no-danger-styled */}
          <SectionHeadline
            paddingBottom={tokens.spacer32}
            dangerouslySetInnerHTML={{
              __html: t('sub_landing_page.listen_together_separately'),
            }}
          />
          {/* eslint-enable @spotify-internal/wtb-rules/no-danger-styled */}
          <LeadParagraph>
            {t('sub_landing_page.separate_accounts')}
          </LeadParagraph>
        </Content>
        <ImageContainer
          data-testid="playlst-display-image-container"
          $mobileImg={cover}
          $tabletImg={cover}
          $desktopImg={cover}
        />
      </ListenTogetherContainer>
    </Wrapper>
  );
};

export default PlaylistsDisplay;
