import * as tokens from 'styles';
import styled from 'styled-components';
import { media } from 'styles/mixins';

const WidthWrapper = styled.div`
  margin: 0 auto;
  padding: ${tokens.spacer64} ${tokens.spacer24};
  width: 100%;

  ${media.mobileSmUp} {
    max-width: 750px;
  }

  ${media.mobileMdUp} {
    max-width: 970px;
    padding-left: ${tokens.spacer48};
    padding-right: ${tokens.spacer48};
  }

  ${media.desktopLgUp} {
    max-width: 1170px;
  }
`;

export default WidthWrapper;
