import React, { MouseEventHandler } from 'react';
import styled, { useTheme } from 'styled-components';
import Type from 'components/Type';
import * as tokens from 'styles';
import { getLongPlanName } from 'lib/i18n';
import UnderlinedLink from 'components/Link/UnderlinedLink';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { PLAN_FAMILY } from 'lib/plans';
import LeadParagraph from 'components/SubLandingPage/LeadParagraph';
import SectionHeadline from 'components/SubLandingPage/SectionHeadline';
import WidthWrapper from 'components/SubLandingPage/Family/WidthWrapper';
import { useTranslation } from 'data/translation/useTranslation';

const Wrapper = styled(WidthWrapper)`
  > * + * {
    margin-top: ${tokens.spacer32};
  }
`;

type AcceptDisplayProps = {
  joinUrl: string;
  termsUrl: string;
  onJoinClick: MouseEventHandler;
  onTermsClick: MouseEventHandler;
};

const AcceptDisplay = ({
  joinUrl,
  termsUrl,
  onJoinClick,
  onTermsClick,
}: AcceptDisplayProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Wrapper>
      <SectionHeadline>{t('family_plan')}</SectionHeadline>
      <LeadParagraph>
        {t('sub_landing_page.six_accounts_under_one_roof')}
      </LeadParagraph>
      <PrimaryButton href={joinUrl} onClick={onJoinClick} $alternateFamily>
        {t('sub_landing_page.accept_invite')}
      </PrimaryButton>
      <Type.P variant="finale">
        <UnderlinedLink
          href={termsUrl}
          onClick={onTermsClick}
          style={{ color: theme.textColor }}
        >
          {t('sub_landing_page.terms.link_plan', {
            plan_name: getLongPlanName(PLAN_FAMILY, t),
          })}
        </UnderlinedLink>
      </Type.P>
    </Wrapper>
  );
};

export default AcceptDisplay;
