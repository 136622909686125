import React from 'react';
import styled from 'styled-components';
import * as tokens from 'styles';
import { getPlanName } from 'lib/i18n';
import Type from 'components/Type';
import { PLAN_FAMILY } from 'lib/plans';
import StepList from 'components/SubLandingPage/StepList';
import StepListItem from 'components/SubLandingPage/StepListItem';
import WidthWrapper from 'components/SubLandingPage/Family/WidthWrapper';
import SectionHeadline from 'components/SubLandingPage/SectionHeadline';
import { useTranslation } from 'data/translation/useTranslation';

const Background = styled.section`
  background-color: ${tokens.white};
`;

const StepsDisplay = () => {
  const { t } = useTranslation();
  return (
    <Background>
      <WidthWrapper>
        <SectionHeadline paddingBottom={tokens.spacer32}>
          {t('sub_landing_page.its_easy_plan', {
            plan_name: getPlanName(PLAN_FAMILY, t),
          })}
        </SectionHeadline>
        <StepList>
          <StepListItem>
            {t('sub_landing_page.topics.tap_accept_invite')}
          </StepListItem>
          <StepListItem>
            {t('sub_landing_page.topics.sign_up_keep')}
          </StepListItem>
          <StepListItem>
            {t('sub_landing_page.topics.confirm_address')}
          </StepListItem>
        </StepList>
        <Type.P>
          {t('sub_landing_page.ps.same_home_plan', {
            plan_name: getPlanName(PLAN_FAMILY, t),
          })}
        </Type.P>
      </WidthWrapper>
    </Background>
  );
};

export default StepsDisplay;
