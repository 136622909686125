import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { UnpaddedLayout } from 'components/Layout';
import PlaylistsDisplay from 'components/SubLandingPage/Family/PlaylistsDisplay';
import PremiumDisplay from 'components/SubLandingPage/Family/PremiumDisplay';
import StepsDisplay from 'components/SubLandingPage/Family/StepsDisplay';
import InvitationDisplay from 'components/SubLandingPage/Family/InvitationDisplay';
import AcceptDisplay from 'components/SubLandingPage/Family/AcceptDisplay';
import { Footer } from '@spotify-internal/masthead-react';

type SubLandingPageProps = ComponentProps<typeof AcceptDisplay> &
  ComponentProps<typeof InvitationDisplay> & {
    mastheadData: any;
  };

const SubLandingPageLayout = styled(UnpaddedLayout)`
  background: var(--background-base);
`;

const SubLandingPage = (props: SubLandingPageProps) => (
  <React.Fragment>
    <SubLandingPageLayout centered>
      <InvitationDisplay {...props} />
      <PlaylistsDisplay />
      <PremiumDisplay />
      <StepsDisplay />
      <AcceptDisplay {...props} />
    </SubLandingPageLayout>
    {props.mastheadData && <Footer {...props.mastheadData.footer} />}
  </React.Fragment>
);

export default SubLandingPage;
